<template>
  <div class="gameplayIntroduction">
    <img
      src="https://cdn.bubbleplan.cn/static/home/back_cc.png"
      alt=""
      class="gameplayIntroduction-bach"
      @click.stop="back()"
    />
    <img
      src="https://cdn.bubbleplan.cn/static/journalism/bubbleProject.png"
      alt=""
      class="gameplayIntroduction-img"
    />
  </div>
</template>
  
  <script>
export default {
  data() {
    return {};
  },
  methods: {
    back() {
      history.back();
    },
  },
};
</script>
  
  
  <style lang="scss" scoped>
.gameplayIntroduction {
  margin-top: -94px;
  position: relative;
  &-img {
    width: 100%;
    height: 1366px;
  }
  &-bach {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 170px;
    left: 90px;
    z-index: 9;
  }
  &-bach:hover {
    cursor: pointer;
  }
}
</style>